.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.setting-page .topic-point {
  background: #fff;
}
.setting-page .yd-cell-item {
  padding-left: 0.36rem;
}
.setting-page .yd-cell-left span {
  font-size: 0.3rem;
  color: #2D2D2D;
}
.setting-page .yd-cell-right {
  height: 1rem;
}
.setting-page .yd-cell-right span {
  font-size: 0.3rem;
  color: #999;
}
.setting-page .yd-cell-item:not(:last-child):after {
  margin-left: 0.36rem;
}
.setting-page .yd-cell:after {
  width: 0;
}
.setting-page Footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  /*  版权信息*/
}
.setting-page Footer .copyright-box {
  font-size: 0.28rem;
  color: #999;
  margin-bottom: 0.38rem;
}
.setting-page Footer .copyright-box div {
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
}
.setting-page Footer .logoutBtn {
  width: 100%;
  height: 0.96rem;
  background: #ff5252;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.96rem;
  text-align: center;
}
